.rdw-image-alignment img {
	max-width: 100% !important;
	height: auto !important;
}
.showing-text {
	line-height: 30px;
}
.ant-notification {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: fixed;
	z-index: 1200;
	width: 384px;
	max-width: calc(100vw - 32px);
	margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
	margin-right: 0;
	margin-left: 24px;
}
.ant-notification-topLeft
	.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft
	.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft
	.ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft
	.ant-notification-fade-appear.ant-notification-fade-appear-active {
	-webkit-animation-name: NotificationLeftFadeIn;
	animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
	font-size: 14px;
	cursor: pointer;
}
.ant-notification-notice {
	position: relative;
	margin-bottom: 16px;
	padding: 16px 24px;
	overflow: hidden;
	line-height: 1.5;
	background: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-notification-notice-message {
	display: inline-block;
	margin-bottom: 8px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
	display: block;
	width: calc(384px - 24px * 2 - 24px - 48px - 100%);
	max-width: 4px;
	background-color: transparent;
	pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
	display: block;
	content: "";
}
.ant-notification-notice-description {
	font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
	padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
	margin-bottom: 4px;
	margin-left: 48px;
	font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
	margin-left: 48px;
	font-size: 14px;
}
.ant-notification-notice-icon {
	position: absolute;
	margin-left: 4px;
	font-size: 24px;
	line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
	color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
	color: #1890ff;
}
.anticon.ant-notification-notice-icon-warning {
	color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
	color: #f5222d;
}
.ant-notification-notice-close {
	position: absolute;
	top: 16px;
	right: 22px;
	color: rgba(0, 0, 0, 0.45);
	outline: none;
}
.ant-notification-notice-close:hover {
	color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
	float: right;
	margin-top: 16px;
}
.ant-notification .notification-fade-effect {
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
	opacity: 0;
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-notification-fade-leave {
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
	-webkit-animation-name: NotificationFadeIn;
	animation-name: NotificationFadeIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
	-webkit-animation-name: NotificationFadeOut;
	animation-name: NotificationFadeOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
@-webkit-keyframes NotificationFadeIn {
	0% {
		left: 384px;
		opacity: 0;
	}
	100% {
		left: 0;
		opacity: 1;
	}
}
@keyframes NotificationFadeIn {
	0% {
		left: 384px;
		opacity: 0;
	}
	100% {
		left: 0;
		opacity: 1;
	}
}
@-webkit-keyframes NotificationLeftFadeIn {
	0% {
		right: 384px;
		opacity: 0;
	}
	100% {
		right: 0;
		opacity: 1;
	}
}
@keyframes NotificationLeftFadeIn {
	0% {
		right: 384px;
		opacity: 0;
	}
	100% {
		right: 0;
		opacity: 1;
	}
}
@-webkit-keyframes NotificationFadeOut {
	0% {
		max-height: 150px;
		margin-bottom: 16px;
		padding-top: 16px 24px;
		padding-bottom: 16px 24px;
		opacity: 1;
	}
	100% {
		max-height: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		opacity: 0;
	}
}
@keyframes NotificationFadeOut {
	0% {
		max-height: 150px;
		margin-bottom: 16px;
		padding-top: 16px 24px;
		padding-bottom: 16px 24px;
		opacity: 1;
	}
	100% {
		max-height: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		opacity: 0;
	}
}

/* message */
.ant-message {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: fixed;
	top: 16px;
	left: 0;
	z-index: 1010;
	width: 100%;
	pointer-events: none;
}
.ant-message-notice {
	padding: 8px;
	text-align: center;
}
.ant-message-notice:first-child {
	margin-top: -8px;
}
.ant-message-notice-content {
	display: inline-block;
	padding: 10px 16px;
	background: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	pointer-events: all;
}
.ant-message-success .anticon {
	color: #52c41a;
}
.ant-message-error .anticon {
	color: #f5222d;
}
.ant-message-warning .anticon {
	color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
	color: #1890ff;
}
.ant-message .anticon {
	position: relative;
	top: 1px;
	margin-right: 8px;
	font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
	overflow: hidden;
	-webkit-animation-name: MessageMoveOut;
	animation-name: MessageMoveOut;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
}
@-webkit-keyframes MessageMoveOut {
	0% {
		max-height: 150px;
		padding: 8px;
		opacity: 1;
	}
	100% {
		max-height: 0;
		padding: 0;
		opacity: 0;
	}
}
@keyframes MessageMoveOut {
	0% {
		max-height: 150px;
		padding: 8px;
		opacity: 1;
	}
	100% {
		max-height: 0;
		padding: 0;
		opacity: 0;
	}
}

/* select */
.ant-select {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	outline: 0;
}
.ant-select ul,
.ant-select ol {
	margin: 0;
	padding: 0;
	list-style: none;
}
.ant-select > ul > li > a {
	padding: 0;
	background-color: #fff;
}
.ant-select-arrow {
	display: inline-block;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	top: 50%;
	right: 11px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	line-height: 1;
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}
.ant-select-arrow > * {
	line-height: 1;
}
.ant-select-arrow svg {
	display: inline-block;
}
.ant-select-arrow::before {
	display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
	display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
	-webkit-transition: -webkit-transform 0.3s;
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-selection {
	display: block;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #fff;
	border: 1px solid rgba(135, 150, 165, 0.15);
	border-top-width: 1.02px;
	border-radius: 4px;
	outline: none;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-select-selection:hover {
	border-color: #448bff;
	border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
	border-color: #448bff;
	border-right-width: 1px !important;
	outline: 0;
}
.ant-select-selection__clear {
	position: absolute;
	top: 50%;
	right: 11px;
	z-index: 1;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	font-style: normal;
	line-height: 12px;
	text-align: center;
	text-transform: none;
	background: #fff;
	cursor: pointer;
	opacity: 0;
	-webkit-transition: color 0.3s ease, opacity 0.15s ease;
	transition: color 0.3s ease, opacity 0.15s ease;
	text-rendering: auto;
}
.ant-select-selection__clear::before {
	display: block;
}
.ant-select-selection__clear:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-select-selection:hover .ant-select-selection__clear {
	opacity: 1;
}
.ant-select-selection-selected-value {
	float: left;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
	padding-right: 0;
}
.ant-select-disabled {
	color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
	background: #f5f5f5;
	cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
	border-color: rgba(135, 150, 165, 0.15);
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
	display: none;
	visibility: hidden;
	pointer-events: none;
}
.ant-select-disabled
	.ant-select-selection--multiple
	.ant-select-selection__choice {
	padding-right: 10px;
	color: rgba(0, 0, 0, 0.33);
	background: #f5f5f5;
}
.ant-select-disabled
	.ant-select-selection--multiple
	.ant-select-selection__choice__remove {
	display: none;
}
.ant-select-selection--single {
	position: relative;
	height: 34px;
	cursor: pointer;
}
.ant-select-selection--single .ant-select-selection__rendered {
	margin-right: 24px;
}
.ant-select-no-arrow .ant-select-selection__rendered {
	margin-right: 11px;
}
.ant-select-selection__rendered {
	position: relative;
	display: block;
	margin-right: 11px;
	margin-left: 11px;
	line-height: 30px;
}
.ant-select-selection__rendered::after {
	display: inline-block;
	width: 0;
	visibility: hidden;
	content: ".";
	pointer-events: none;
}
.ant-select-lg {
	font-size: 16px;
}
.ant-select-lg .ant-select-selection--single {
	height: 40px;
}
.ant-select-lg .ant-select-selection__rendered {
	line-height: 38px;
}
.ant-select-lg .ant-select-selection--multiple {
	min-height: 40px;
}
.ant-select-lg
	.ant-select-selection--multiple
	.ant-select-selection__rendered
	li {
	height: 34px;
	line-height: 34px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
	top: 20px;
}
.ant-select-sm .ant-select-selection--single {
	height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
	margin-left: 7px;
	line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple {
	min-height: 24px;
}
.ant-select-sm
	.ant-select-selection--multiple
	.ant-select-selection__rendered
	li {
	height: 16px;
	line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
	top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
	right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
	color: rgba(0, 0, 0, 0.25);
	cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
	color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
	position: relative;
	display: inline-block;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
	position: absolute;
	top: 50%;
	right: 9px;
	left: 0;
	max-width: 100%;
	height: 20px;
	margin-top: -10px;
	overflow: hidden;
	color: #bfbfbf;
	line-height: 20px;
	white-space: nowrap;
	text-align: left;
	text-overflow: ellipsis;
}
.ant-select-search__field__placeholder {
	left: 12px;
}
.ant-select-search__field__mirror {
	position: absolute;
	top: 0;
	left: 0;
	white-space: pre;
	opacity: 0;
	pointer-events: none;
}
.ant-select-search--inline {
	position: absolute;
	width: 100%;
	height: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
	width: 100%;
	height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
	width: 100%;
	height: 100%;
	font-size: 100%;
	line-height: 1;
	background: transparent;
	border-width: 0;
	border-radius: 4px;
	outline: 0;
}
.ant-select-search--inline > i {
	float: right;
}
.ant-select-selection--multiple {
	min-height: 34px;
	cursor: text;
	zoom: 1;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
	display: table;
	content: "";
}
.ant-select-selection--multiple::after {
	clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
	position: static;
	float: left;
	width: auto;
	max-width: 100%;
	padding: 0;
}
.ant-select-selection--multiple
	.ant-select-search--inline
	.ant-select-search__field {
	width: 0.75em;
	max-width: 100%;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
	height: auto;
	margin-bottom: -3px;
	margin-left: 5px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
	margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
	height: 24px;
	margin-top: 5px;
	line-height: 22px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
	position: relative;
	float: left;
	max-width: 99%;
	margin-right: 4px;
	padding: 0 20px 0 10px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.65);
	background-color: #fafafa;
	border: 1px solid #e8e8e8;
	border-radius: 2px;
	cursor: default;
	-webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
	padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	right: 4px;
	color: rgba(0, 0, 0, 0.45);
	font-weight: bold;
	line-height: inherit;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	display: inline-block;
	font-size: 12px;
	font-size: 10px \9;
	-webkit-transform: scale(0.83333333) rotate(0deg);
	-ms-transform: scale(0.83333333) rotate(0deg);
	transform: scale(0.83333333) rotate(0deg);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
	line-height: 1;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
	display: inline-block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
	display: none;
}
.ant-select-selection--multiple
	.ant-select-selection__choice__remove
	.ant-select-selection--multiple
	.ant-select-selection__choice__remove-icon {
	display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
	font-size: 12px;
	display: flex;
	align-items: center;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
	color: rgba(0, 0, 0, 0.75);
}
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
	top: 16px;
}
.ant-select-allow-clear
	.ant-select-selection--single
	.ant-select-selection-selected-value {
	padding-right: 16px;
}
.ant-select-allow-clear
	.ant-select-selection--multiple
	.ant-select-selection__rendered,
.ant-select-show-arrow
	.ant-select-selection--multiple
	.ant-select-selection__rendered {
	margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
	border-color: #448bff;
	border-right-width: 1px !important;
	outline: 0;
}
.ant-select-combobox .ant-select-arrow {
	display: none;
}
.ant-select-combobox .ant-select-search--inline {
	float: none;
	width: 100%;
	height: 100%;
}
.ant-select-combobox .ant-select-search__field__wrap {
	width: 100%;
	height: 100%;
}
.ant-select-combobox .ant-select-search__field {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
.ant-select-combobox.ant-select-allow-clear
	.ant-select-selection:hover
	.ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow
	.ant-select-selection:hover
	.ant-select-selection__rendered {
	margin-right: 20px;
}
.ant-select-dropdown {
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 14px;
	font-variant: initial;
	background-color: #fff;
	border-radius: 4px;
	outline: none;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
	display: none;
}
.ant-select-dropdown-menu {
	max-height: 250px;
	margin-bottom: 0;
	padding-left: 0;
	overflow: auto;
	list-style: none;
	outline: none;
}
.ant-select-dropdown-menu-item-group-list {
	margin: 0;
	padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
	padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
	height: 32px;
	padding: 0 12px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	line-height: 32px;
}
.ant-select-dropdown-menu-item-group-list
	.ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child)
	.ant-select-dropdown-menu-item-group-list
	.ant-select-dropdown-menu-item:last-child {
	border-radius: 0;
}
.ant-select-dropdown-menu-item {
	position: relative;
	display: block;
	padding: 5px 12px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	line-height: 22px;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item:first-child {
	border-radius: 4px 4px 0 0;
}
.ant-select-dropdown-menu-item:last-child {
	border-radius: 0 0 4px 4px;
}
.ant-select-dropdown-menu-item-selected {
	color: rgba(0, 0, 0, 0.65);
	font-weight: 600;
	background-color: #fafafa;
}
.ant-select-dropdown-menu-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item-divider {
	height: 1px;
	margin: 1px 0;
	overflow: hidden;
	line-height: 0;
	background-color: #e8e8e8;
}
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item {
	padding-right: 32px;
}
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item
	.ant-select-selected-icon {
	position: absolute;
	top: 50%;
	right: 12px;
	color: transparent;
	font-weight: bold;
	font-size: 12px;
	text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item:hover
	.ant-select-selected-icon {
	color: rgba(0, 0, 0, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item-disabled
	.ant-select-selected-icon {
	display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item-selected
	.ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item-selected:hover
	.ant-select-selected-icon {
	display: inline-block;
	color: #1890ff;
}
.ant-select-dropdown--empty.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item {
	padding-right: 12px;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
	display: block;
}

.ant-select-auto-complete {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
}
.ant-select-auto-complete.ant-select .ant-select-selection {
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
	height: 100%;
	margin-right: 0;
	margin-left: 0;
	line-height: 32px;
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
	margin-right: 12px;
	margin-left: 12px;
}
.ant-select-auto-complete.ant-select .ant-select-selection--single {
	height: auto;
}
.ant-select-auto-complete.ant-select .ant-select-search--inline {
	position: static;
	float: left;
}
.ant-select-auto-complete.ant-select-allow-clear
	.ant-select-selection:hover
	.ant-select-selection__rendered {
	margin-right: 0 !important;
}
.ant-select-auto-complete.ant-select .ant-input {
	height: 32px;
	line-height: 1.5;
	background: transparent;
	border-width: 1px;
}
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
	border-color: #448bff;
	border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select .ant-input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
	background-color: transparent;
}
.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
	border-color: rgba(135, 150, 165, 0.15);
	border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
	line-height: 40px;
}
.ant-select-auto-complete.ant-select-lg .ant-input {
	height: 40px;
	padding-top: 6px;
	padding-bottom: 6px;
}
.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
	line-height: 24px;
}
.ant-select-auto-complete.ant-select-sm .ant-input {
	height: 24px;
	padding-top: 1px;
	padding-bottom: 1px;
}
.ant-input-group
	> .ant-select-auto-complete
	.ant-select-search__field.ant-input-affix-wrapper {
	display: inline;
	float: none;
}

.ant-select-auto-complete {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
}
.ant-select-auto-complete.ant-select .ant-select-selection {
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
	height: 100%;
	margin-right: 0;
	margin-left: 0;
	line-height: 32px;
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
	margin-right: 12px;
	margin-left: 12px;
}
.ant-select-auto-complete.ant-select .ant-select-selection--single {
	height: auto;
}
.ant-select-auto-complete.ant-select .ant-select-search--inline {
	position: static;
	float: left;
}
.ant-select-auto-complete.ant-select-allow-clear
	.ant-select-selection:hover
	.ant-select-selection__rendered {
	margin-right: 0 !important;
}
.ant-select-auto-complete.ant-select .ant-input {
	height: 32px;
	line-height: 1.5;
	background: transparent;
	border-width: 1px;
}
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
	border-color: #448bff;
	border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select .ant-input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
	background-color: transparent;
}
.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
	border-color: rgba(135, 150, 165, 0.15);
	border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
	line-height: 40px;
}
.ant-select-auto-complete.ant-select-lg .ant-input {
	height: 40px;
	padding-top: 6px;
	padding-bottom: 6px;
}
.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
	line-height: 24px;
}
.ant-select-auto-complete.ant-select-sm .ant-input {
	height: 24px;
	padding-top: 1px;
	padding-bottom: 1px;
}
.ant-input-group
	> .ant-select-auto-complete
	.ant-select-search__field.ant-input-affix-wrapper {
	display: inline;
	float: none;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	outline: 0;
}
.ant-select ul,
.ant-select ol {
	margin: 0;
	padding: 0;
	list-style: none;
}
.ant-select > ul > li > a {
	padding: 0;
	background-color: #fff;
}
.ant-select-arrow {
	display: inline-block;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	top: 50%;
	right: 11px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	line-height: 1;
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}
.ant-select-arrow > * {
	line-height: 1;
}
.ant-select-arrow svg {
	display: inline-block;
}
.ant-select-arrow::before {
	display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
	display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
	-webkit-transition: -webkit-transform 0.3s;
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-selection {
	display: block;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #fff;
	border: 1px solid rgba(135, 150, 165, 0.15);
	border-top-width: 1.02px;
	border-radius: 4px;
	outline: none;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-select-selection:hover {
	border-color: #448bff;
	border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
	border-color: #448bff;
	border-right-width: 1px !important;
	outline: 0;
}
.ant-select-selection__clear {
	position: absolute;
	top: 50%;
	right: 11px;
	z-index: 1;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	font-style: normal;
	line-height: 12px;
	text-align: center;
	text-transform: none;
	background: #fff;
	cursor: pointer;
	opacity: 0;
	-webkit-transition: color 0.3s ease, opacity 0.15s ease;
	transition: color 0.3s ease, opacity 0.15s ease;
	text-rendering: auto;
}
.ant-select-selection__clear::before {
	display: block;
}
.ant-select-selection__clear:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-select-selection:hover .ant-select-selection__clear {
	opacity: 1;
}
.ant-select-selection-selected-value {
	float: left;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
	padding-right: 0;
}
.ant-select-disabled {
	color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
	background: #f5f5f5;
	cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
	border-color: rgba(135, 150, 165, 0.15);
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
	display: none;
	visibility: hidden;
	pointer-events: none;
}
.ant-select-disabled
	.ant-select-selection--multiple
	.ant-select-selection__choice {
	padding-right: 10px;
	color: rgba(0, 0, 0, 0.33);
	background: #f5f5f5;
}
.ant-select-disabled
	.ant-select-selection--multiple
	.ant-select-selection__choice__remove {
	display: none;
}
.ant-select-selection--single .ant-select-selection__rendered {
	margin-right: 24px;
}
.ant-select-no-arrow .ant-select-selection__rendered {
	margin-right: 11px;
}
.ant-select-selection__rendered {
	position: relative;
	display: block;
	margin-right: 11px;
	margin-left: 11px;
	line-height: 30px;
}
.ant-select-selection__rendered::after {
	display: inline-block;
	width: 0;
	visibility: hidden;
	content: ".";
	pointer-events: none;
}
.ant-select-lg {
	font-size: 16px;
}
.ant-select-lg .ant-select-selection--single {
	height: 40px;
}
.ant-select-lg .ant-select-selection__rendered {
	line-height: 38px;
}
.ant-select-lg .ant-select-selection--multiple {
	min-height: 40px;
}
.ant-select-lg
	.ant-select-selection--multiple
	.ant-select-selection__rendered
	li {
	height: 34px;
	line-height: 34px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
	top: 20px;
}
.ant-select-sm .ant-select-selection--single {
	height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
	margin-left: 7px;
	line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple {
	min-height: 24px;
}
.ant-select-sm
	.ant-select-selection--multiple
	.ant-select-selection__rendered
	li {
	height: 16px;
	line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
	top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
	right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
	color: rgba(0, 0, 0, 0.25);
	cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
	color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
	position: relative;
	display: inline-block;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
	position: absolute;
	top: 50%;
	right: 9px;
	left: 0;
	max-width: 100%;
	height: 20px;
	margin-top: -10px;
	overflow: hidden;
	color: #bfbfbf;
	line-height: 20px;
	white-space: nowrap;
	text-align: left;
	text-overflow: ellipsis;
}
.ant-select-search__field__placeholder {
	left: 12px;
}
.ant-select-search__field__mirror {
	position: absolute;
	top: 0;
	left: 0;
	white-space: pre;
	opacity: 0;
	pointer-events: none;
}
.ant-select-search--inline {
	position: absolute;
	width: 100%;
	height: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
	width: 100%;
	height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
	width: 100%;
	height: 100%;
	font-size: 100%;
	line-height: 1;
	background: transparent;
	border-width: 0;
	border-radius: 4px;
	outline: 0;
}
.ant-select-search--inline > i {
	float: right;
}
.ant-select-selection--multiple {
	min-height: 34px;
	cursor: text;
	zoom: 1;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
	display: table;
	content: "";
}
.ant-select-selection--multiple::after {
	clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
	position: static;
	float: left;
	width: auto;
	max-width: 100%;
	padding: 0;
}
.ant-select-selection--multiple
	.ant-select-search--inline
	.ant-select-search__field {
	width: 0.75em;
	max-width: 100%;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
	height: auto;
	margin-bottom: -3px;
	margin-left: 5px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
	margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
	height: 24px;
	margin-top: 4px;
	line-height: 22px;
	display: flex;
	align-items: center;
}
.ant-select-selection--multiple .ant-select-selection__choice {
	position: relative;
	float: left;
	max-width: 99%;
	margin-right: 4px;
	padding: 0 20px 0 10px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.65);
	background-color: #fafafa;
	border: 1px solid #e8e8e8;
	border-radius: 2px;
	cursor: default;
	-webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
	padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	right: 4px;
	color: rgba(0, 0, 0, 0.45);
	font-weight: bold;
	line-height: inherit;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	display: inline-block;
	font-size: 12px;
	font-size: 10px \9;
	-webkit-transform: scale(0.83333333) rotate(0deg);
	-ms-transform: scale(0.83333333) rotate(0deg);
	transform: scale(0.83333333) rotate(0deg);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
	line-height: 1;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
	display: inline-block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
	display: none;
}
.ant-select-selection--multiple
	.ant-select-selection__choice__remove
	.ant-select-selection--multiple
	.ant-select-selection__choice__remove-icon {
	display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
	font-size: 12px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
	color: rgba(0, 0, 0, 0.75);
}
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
	top: 16px;
}
.ant-select-allow-clear
	.ant-select-selection--single
	.ant-select-selection-selected-value {
	padding-right: 16px;
}
.ant-select-allow-clear
	.ant-select-selection--multiple
	.ant-select-selection__rendered,
.ant-select-show-arrow
	.ant-select-selection--multiple
	.ant-select-selection__rendered {
	margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
	border-color: #448bff;
	border-right-width: 1px !important;
	outline: 0;
}
.ant-select-combobox .ant-select-arrow {
	display: none;
}
.ant-select-combobox .ant-select-search--inline {
	float: none;
	width: 100%;
	height: 100%;
}
.ant-select-combobox .ant-select-search__field__wrap {
	width: 100%;
	height: 100%;
}
.ant-select-combobox .ant-select-search__field {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
.ant-select-combobox.ant-select-allow-clear
	.ant-select-selection:hover
	.ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow
	.ant-select-selection:hover
	.ant-select-selection__rendered {
	margin-right: 20px;
}
.ant-select-dropdown {
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 14px;
	font-variant: initial;
	background-color: #fff;
	border-radius: 4px;
	outline: none;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
	display: none;
}
.ant-select-dropdown-menu {
	max-height: 250px;
	margin-bottom: 0;
	padding-left: 0;
	overflow: auto;
	list-style: none;
	outline: none;
}
.ant-select-dropdown-menu-item-group-list {
	margin: 0;
	padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
	padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
	height: 32px;
	padding: 0 12px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	line-height: 32px;
}
.ant-select-dropdown-menu-item-group-list
	.ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child)
	.ant-select-dropdown-menu-item-group-list
	.ant-select-dropdown-menu-item:last-child {
	border-radius: 0;
}
.ant-select-dropdown-menu-item {
	position: relative;
	display: block;
	padding: 5px 12px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	line-height: 22px;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item:first-child {
	border-radius: 4px 4px 0 0;
}
.ant-select-dropdown-menu-item:last-child {
	border-radius: 0 0 4px 4px;
}
.ant-select-dropdown-menu-item-selected {
	color: rgba(0, 0, 0, 0.65);
	font-weight: 600;
	background-color: #fafafa;
}
.ant-select-dropdown-menu-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item-divider {
	height: 1px;
	margin: 1px 0;
	overflow: hidden;
	line-height: 0;
	background-color: #e8e8e8;
}
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item {
	padding-right: 32px;
}
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item
	.ant-select-selected-icon {
	position: absolute;
	top: 50%;
	right: 12px;
	color: transparent;
	font-weight: bold;
	font-size: 12px;
	text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item:hover
	.ant-select-selected-icon {
	color: rgba(0, 0, 0, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item-disabled
	.ant-select-selected-icon {
	display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item-selected
	.ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item-selected:hover
	.ant-select-selected-icon {
	display: inline-block;
	color: #1890ff;
}
.ant-select-dropdown--empty.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item {
	padding-right: 12px;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
	display: block;
}

/* ant input */
.ant-empty {
	margin: 0 8px;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
}
.ant-empty-image {
	height: 100px;
	margin-bottom: 8px;
}
.ant-empty-image img {
	height: 100%;
}
.ant-empty-image svg {
	height: 100%;
	margin: auto;
}
.ant-empty-description {
	margin: 0;
}
.ant-empty-footer {
	margin-top: 16px;
}
.ant-empty-normal {
	margin: 32px 0;
	color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
	height: 40px;
}
.ant-empty-small {
	margin: 8px 0;
	color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
	height: 35px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-variant: tabular-nums;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	width: 100%;
	height: 32px;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5;
	background-color: #fff;
	background-image: none;
	border: 1px solid rgba(135, 150, 165, 0.15);
	border-radius: 4px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-input::-moz-placeholder {
	color: #bfbfbf;
	opacity: 1;
}
.ant-input:-ms-input-placeholder {
	color: #bfbfbf;
}
.ant-input::-webkit-input-placeholder {
	color: #bfbfbf;
}
.ant-input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-input:hover {
	border-color: #448bff;
	border-right-width: 1px !important;
}
.ant-input:focus {
	border-color: #448bff;
	border-right-width: 1px !important;
	outline: 0;
}
.ant-input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-input-disabled:hover {
	border-color: rgba(135, 150, 165, 0.15);
	border-right-width: 1px !important;
}
.ant-input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-input[disabled]:hover {
	border-color: rgba(135, 150, 165, 0.15);
	border-right-width: 1px !important;
}
textarea.ant-input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5;
	vertical-align: bottom;
	-webkit-transition: all 0.3s, height 0s;
	transition: all 0.3s, height 0s;
}
.ant-input-lg {
	height: 40px;
	padding: 6px 11px;
	font-size: 16px;
}
.ant-input-sm {
	height: 24px;
	padding: 1px 7px;
}
.ant-input-group {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	display: table;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
}
.ant-input-group[class*="col-"] {
	float: none;
	padding-right: 0;
	padding-left: 0;
}
.ant-input-group > [class*="col-"] {
	padding-right: 8px;
}
.ant-input-group > [class*="col-"]:last-child {
	padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
	display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
	border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
	width: 1px;
	white-space: nowrap;
	vertical-align: middle;
}
.ant-input-group-wrap > * {
	display: block !important;
}
.ant-input-group .ant-input {
	float: left;
	width: 100%;
	margin-bottom: 0;
	text-align: inherit;
}
.ant-input-group .ant-input:focus {
	z-index: 1;
	border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
	z-index: 1;
	border-right-width: 1px;
}
.ant-input-group-addon {
	position: relative;
	padding: 0 11px;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	font-size: 14px;
	text-align: center;
	background-color: #fafafa;
	border: 1px solid rgba(135, 150, 165, 0.15);
	border-radius: 4px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
	margin: -5px -11px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
	margin: -1px;
	background-color: inherit;
	border: 1px solid transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
	color: #1890ff;
}
.ant-input-group-addon > i:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: "";
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
	border-right: 0;
}
.ant-input-group-addon:last-child {
	border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
	height: 40px;
	padding: 6px 11px;
	font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
	height: 24px;
	padding: 1px 7px;
}
.ant-input-group-lg .ant-select-selection--single {
	height: 40px;
}
.ant-input-group-sm .ant-select-selection--single {
	height: 24px;
}
.ant-input-group .ant-input-affix-wrapper {
	display: table-cell;
	float: left;
	width: 100%;
}
.ant-input-group.ant-input-group-compact {
	display: block;
	zoom: 1;
}
.ant-input-group.ant-input-group-compact::before,
.ant-input-group.ant-input-group-compact::after {
	display: table;
	content: "";
}
.ant-input-group.ant-input-group-compact::after {
	clear: both;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact
	> .ant-input:not(:first-child):not(:last-child) {
	border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact
	> .ant-input:not(:first-child):not(:last-child):hover {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact
	> .ant-input:not(:first-child):not(:last-child):focus {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
	display: inline-block;
	float: none;
	vertical-align: top;
	border-radius: 0;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
	margin-right: -1px;
	border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
	float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-mention-wrapper
	.ant-mention-editor,
.ant-input-group.ant-input-group-compact
	> .ant-time-picker
	.ant-time-picker-input {
	border-right-width: 1px;
	border-radius: 0;
}
.ant-input-group.ant-input-group-compact
	> .ant-select
	> .ant-select-selection:hover,
.ant-input-group.ant-input-group-compact
	> .ant-calendar-picker
	.ant-input:hover,
.ant-input-group.ant-input-group-compact
	> .ant-select-auto-complete
	.ant-input:hover,
.ant-input-group.ant-input-group-compact
	> .ant-cascader-picker
	.ant-input:hover,
.ant-input-group.ant-input-group-compact
	> .ant-mention-wrapper
	.ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact
	> .ant-time-picker
	.ant-time-picker-input:hover {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact
	> .ant-select
	> .ant-select-selection:focus,
.ant-input-group.ant-input-group-compact
	> .ant-calendar-picker
	.ant-input:focus,
.ant-input-group.ant-input-group-compact
	> .ant-select-auto-complete
	.ant-input:focus,
.ant-input-group.ant-input-group-compact
	> .ant-cascader-picker
	.ant-input:focus,
.ant-input-group.ant-input-group-compact
	> .ant-mention-wrapper
	.ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact
	> .ant-time-picker
	.ant-time-picker-input:focus {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact
	> .ant-select:first-child
	> .ant-select-selection,
.ant-input-group.ant-input-group-compact
	> .ant-calendar-picker:first-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-select-auto-complete:first-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-cascader-picker:first-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-mention-wrapper:first-child
	.ant-mention-editor,
.ant-input-group.ant-input-group-compact
	> .ant-time-picker:first-child
	.ant-time-picker-input {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact
	> .ant-select:last-child
	> .ant-select-selection,
.ant-input-group.ant-input-group-compact
	> .ant-calendar-picker:last-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-select-auto-complete:last-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-cascader-picker:last-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-cascader-picker-focused:last-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-mention-wrapper:last-child
	.ant-mention-editor,
.ant-input-group.ant-input-group-compact
	> .ant-time-picker:last-child
	.ant-time-picker-input {
	border-right-width: 1px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.ant-input-group.ant-input-group-compact
	> .ant-select-auto-complete
	.ant-input {
	vertical-align: top;
}
.ant-input-group-wrapper {
	display: inline-block;
	width: 100%;
	text-align: start;
	vertical-align: top;
}
.ant-input-affix-wrapper {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	width: 100%;
	text-align: start;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
	border-color: #448bff;
	border-right-width: 1px !important;
}
.ant-input-affix-wrapper .ant-input {
	position: relative;
	text-align: inherit;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
	position: absolute;
	top: 50%;
	z-index: 2;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: rgba(0, 0, 0, 0.65);
	line-height: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
	line-height: 1.5;
}
.ant-input-affix-wrapper .ant-input-disabled ~ .ant-input-suffix .anticon {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-input-affix-wrapper .ant-input-prefix {
	left: 12px;
}
.ant-input-affix-wrapper .ant-input-suffix {
	right: 12px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
	padding-left: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
	padding-right: 30px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-with-clear-btn
	.ant-input:not(:last-child) {
	padding-right: 49px;
}
.ant-input-affix-wrapper .ant-input {
	min-height: 100%;
}
.ant-input-password-icon {
	color: rgba(0, 0, 0, 0.45);
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-input-password-icon:hover {
	color: #333;
}
.ant-input-clear-icon {
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	vertical-align: 0;
	cursor: pointer;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
}
.ant-input-clear-icon:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-input-clear-icon:active {
	color: rgba(0, 0, 0, 0.65);
}
.ant-input-clear-icon + i {
	margin-left: 6px;
}
.ant-input-search-icon {
	color: rgba(0, 0, 0, 0.45);
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-input-search-icon:hover {
	color: rgba(0, 0, 0, 0.8);
}
.ant-input-search-enter-button input {
	border-right: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
	padding: 0;
	border: 0;
}
.ant-input-search-enter-button
	+ .ant-input-group-addon
	.ant-input-search-button,
.ant-input-search-enter-button
	input
	+ .ant-input-group-addon
	.ant-input-search-button {
	width: 100%;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

/* ant radio */
.ant-radio-group {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	display: inline-block;
	line-height: unset;
}
.ant-radio-wrapper {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	margin-right: 8px;
	white-space: nowrap;
	cursor: pointer;
}
.ant-radio {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	line-height: 1;
	white-space: nowrap;
	vertical-align: sub;
	outline: none;
	cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	border-color: #FF8345;
}
.ant-radio-input:focus + .ant-radio-inner {
	-webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
	box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
}
.ant-radio-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #1890ff;
	border-radius: 50%;
	visibility: hidden;
	-webkit-animation: antRadioEffect 0.36s ease-in-out;
	animation: antRadioEffect 0.36s ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	content: "";
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
	visibility: visible;
}
.ant-radio-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-color: rgba(135, 150, 165, 0.15);
	border-style: solid;
	border-width: 1px;
	border-radius: 100px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-radio-inner::after {
	position: absolute;
	top: 3px;
	left: 3px;
	display: table;
	width: 8px;
	height: 8px;
	background-color: #FF8345;
	border-top: 0;
	border-left: 0;
	border-radius: 8px;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	opacity: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	content: " ";
}
.ant-radio-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	cursor: pointer;
	opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
	border-color: #FF8345;
}
.ant-radio-checked .ant-radio-inner::after {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
	-webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled .ant-radio-inner {
	background-color: #f5f5f5;
	border-color: rgba(135, 150, 165, 0.15) !important;
	cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
	background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
	cursor: not-allowed;
}
.ant-radio-disabled + span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
span.ant-radio + * {
	padding-right: 8px;
	padding-left: 8px;
}
.ant-radio-button-wrapper {
	position: relative;
	display: inline-block;
	height: 32px;
	margin: 0;
	padding: 0 15px;
	color: rgba(0, 0, 0, 0.65);
	line-height: 30px;
	background: #fff;
	border: 1px solid rgba(135, 150, 165, 0.15);
	border-top-width: 1.02px;
	border-left: 0;
	cursor: pointer;
	-webkit-transition: color 0.3s, background 0.3s, border-color 0.3s;
	transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.ant-radio-button-wrapper a {
	color: rgba(0, 0, 0, 0.65);
}
.ant-radio-button-wrapper > .ant-radio-button {
	display: block;
	width: 0;
	height: 0;
	margin-left: 0;
}
.ant-radio-group-large .ant-radio-button-wrapper {
	height: 40px;
	font-size: 16px;
	line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
	height: 24px;
	padding: 0 7px;
	line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
	position: absolute;
	top: 0;
	left: -1px;
	display: block;
	width: 1px;
	height: 100%;
	background-color: rgba(135, 150, 165, 0.15);
	content: "";
}
.ant-radio-button-wrapper:first-child {
	border-left: 1px solid rgba(135, 150, 165, 0.15);
	border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
	border-radius: 0 4px 4px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
	border-radius: 4px;
}
.ant-radio-button-wrapper:hover {
	position: relative;
	color: #1890ff;
}
.ant-radio-button-wrapper:focus-within {
	outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type="checkbox"],
.ant-radio-button-wrapper input[type="radio"] {
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	z-index: 1;
	color: #1890ff;
	background: #fff;
	border-color: #1890ff;
	-webkit-box-shadow: -1px 0 0 0 #1890ff;
	box-shadow: -1px 0 0 0 #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
	background-color: #1890ff !important;
	opacity: 0.1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
	border-color: #1890ff;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #448bff;
	border-color: #448bff;
	-webkit-box-shadow: -1px 0 0 0 #448bff;
	box-shadow: -1px 0 0 0 #448bff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	color: #096dd9;
	border-color: #096dd9;
	-webkit-box-shadow: -1px 0 0 0 #096dd9;
	box-shadow: -1px 0 0 0 #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-group-solid
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: #fff;
	background: #1890ff;
	border-color: #1890ff;
}
.ant-radio-group-solid
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #fff;
	background: #448bff;
	border-color: #448bff;
}
.ant-radio-group-solid
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	color: #fff;
	background: #096dd9;
	border-color: #096dd9;
}
.ant-radio-group-solid
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-button-wrapper-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: rgba(135, 150, 165, 0.15);
	cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: rgba(135, 150, 165, 0.15);
}
.ant-radio-button-wrapper-disabled:first-child {
	border-left-color: rgba(135, 150, 165, 0.15);
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
	color: #fff;
	background-color: #e6e6e6;
	border-color: rgba(135, 150, 165, 0.15);
	-webkit-box-shadow: none;
	box-shadow: none;
}
@-webkit-keyframes antRadioEffect {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
		opacity: 0;
	}
}
@keyframes antRadioEffect {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
		opacity: 0;
	}
}
@supports (-moz-appearance: meterbar) and
	(background-blend-mode: difference, normal) {
	.ant-radio {
		vertical-align: text-bottom;
	}
}

/*  */
.ant-input-group-addon .ant-select {
	margin: -5px -11px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
	margin: -1px;
	background-color: inherit;
	border: 1px solid transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
	color: #1890ff;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-select-selection--single {
	height: 40px;
}
.ant-input-group-sm .ant-select-selection--single {
	height: 24px;
}
@-webkit-keyframes antCheckboxEffect {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
		opacity: 0;
	}
}
@keyframes antCheckboxEffect {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
		opacity: 0;
	}
}

/* calender */
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-mention-wrapper
	.ant-mention-editor,
.ant-input-group.ant-input-group-compact
	> .ant-time-picker
	.ant-time-picker-input {
	border-right-width: 1px;
	border-radius: 0;
}
.ant-input-group.ant-input-group-compact
	> .ant-select
	> .ant-select-selection:hover,
.ant-input-group.ant-input-group-compact
	> .ant-calendar-picker
	.ant-input:hover,
.ant-input-group.ant-input-group-compact
	> .ant-select-auto-complete
	.ant-input:hover,
.ant-input-group.ant-input-group-compact
	> .ant-cascader-picker
	.ant-input:hover,
.ant-input-group.ant-input-group-compact
	> .ant-mention-wrapper
	.ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact
	> .ant-time-picker
	.ant-time-picker-input:hover {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact
	> .ant-select
	> .ant-select-selection:focus,
.ant-input-group.ant-input-group-compact
	> .ant-calendar-picker
	.ant-input:focus,
.ant-input-group.ant-input-group-compact
	> .ant-select-auto-complete
	.ant-input:focus,
.ant-input-group.ant-input-group-compact
	> .ant-cascader-picker
	.ant-input:focus,
.ant-input-group.ant-input-group-compact
	> .ant-mention-wrapper
	.ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact
	> .ant-time-picker
	.ant-time-picker-input:focus {
	z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact
	> .ant-select:first-child
	> .ant-select-selection,
.ant-input-group.ant-input-group-compact
	> .ant-calendar-picker:first-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-select-auto-complete:first-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-cascader-picker:first-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-mention-wrapper:first-child
	.ant-mention-editor,
.ant-input-group.ant-input-group-compact
	> .ant-time-picker:first-child
	.ant-time-picker-input {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact
	> .ant-select:last-child
	> .ant-select-selection,
.ant-input-group.ant-input-group-compact
	> .ant-calendar-picker:last-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-select-auto-complete:last-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-cascader-picker:last-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-cascader-picker-focused:last-child
	.ant-input,
.ant-input-group.ant-input-group-compact
	> .ant-mention-wrapper:last-child
	.ant-mention-editor,
.ant-input-group.ant-input-group-compact
	> .ant-time-picker:last-child
	.ant-time-picker-input {
	border-right-width: 1px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.ant-time-picker-panel {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: absolute;
	z-index: 1050;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
		"Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
}
.ant-time-picker-panel-inner {
	position: relative;
	left: -2px;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 4px;
	outline: none;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-time-picker-panel-input {
	width: 100%;
	max-width: 154px;
	margin: 0;
	padding: 0;
	line-height: normal;
	border: 0;
	outline: 0;
	cursor: auto;
}
.ant-time-picker-panel-input::-moz-placeholder {
	color: #bfbfbf;
	opacity: 1;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
	color: #bfbfbf;
}
.ant-time-picker-panel-input::-webkit-input-placeholder {
	color: #bfbfbf;
}
.ant-time-picker-panel-input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-time-picker-panel-input-wrap {
	position: relative;
	padding: 7px 2px 7px 12px;
	border-bottom: 1px solid #e8e8e8;
}
.ant-time-picker-panel-input-invalid {
	border-color: #f5222d;
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
	max-width: 112px;
}
.ant-time-picker-panel-select {
	position: relative;
	float: left;
	width: 56px;
	max-height: 192px;
	overflow: hidden;
	font-size: 14px;
	border-left: 1px solid #e8e8e8;
}
.ant-time-picker-panel-select:hover {
	overflow-y: auto;
}
.ant-time-picker-panel-select:first-child {
	margin-left: 0;
	border-left: 0;
}
.ant-time-picker-panel-select:last-child {
	border-right: 0;
}
.ant-time-picker-panel-select:only-child {
	width: 100%;
}
.ant-time-picker-panel-select ul {
	width: 56px;
	margin: 0;
	padding: 0 0 160px;
	list-style: none;
}
.ant-time-picker-panel-select li {
	width: 100%;
	height: 32px;
	margin: 0;
	padding: 0 0 0 12px;
	line-height: 32px;
	text-align: left;
	list-style: none;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-time-picker-panel-select li:focus {
	color: #1890ff;
	font-weight: 600;
	outline: none;
}
.ant-time-picker-panel-select li:hover {
	background: #e6f7ff;
}
li.ant-time-picker-panel-select-option-selected {
	font-weight: 600;
	background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-selected:hover {
	background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-disabled {
	color: rgba(0, 0, 0, 0.25);
}
li.ant-time-picker-panel-select-option-disabled:hover {
	background: transparent;
	cursor: not-allowed;
}
.ant-time-picker-panel-combobox {
	zoom: 1;
}
.ant-time-picker-panel-combobox::before,
.ant-time-picker-panel-combobox::after {
	display: table;
	content: "";
}
.ant-time-picker-panel-combobox::after {
	clear: both;
}
.ant-time-picker-panel-addon {
	padding: 8px;
	border-top: 1px solid #e8e8e8;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
}
.ant-time-picker {
	min-width: 100%;
	max-width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	width: 128px;
	outline: none;
	cursor: text;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}
.ant-time-picker-input {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 32px;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-time-picker-input::-moz-placeholder {
	color: #bfbfbf;
	opacity: 1;
}
.ant-time-picker-input:-ms-input-placeholder {
	color: #bfbfbf;
}
.ant-time-picker-input::-webkit-input-placeholder {
	color: #bfbfbf;
}
.ant-time-picker-input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-time-picker-input:hover {
	border-color: #40a9ff;
	border-right-width: 1px !important;
}
.ant-time-picker-input:focus {
	border-color: #40a9ff;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
	box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-time-picker-input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-time-picker-input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-time-picker-input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
textarea.ant-time-picker-input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5;
	vertical-align: bottom;
	-webkit-transition: all 0.3s, height 0s;
	transition: all 0.3s, height 0s;
}
.ant-time-picker-input-lg {
	height: 40px;
	padding: 6px 11px;
	font-size: 16px;
}
.ant-time-picker-input-sm {
	height: 24px;
	padding: 1px 7px;
}
.ant-time-picker-input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-time-picker-open {
	opacity: 0;
}
.ant-time-picker-icon,
.ant-time-picker-clear {
	position: absolute;
	top: 50%;
	right: 11px;
	z-index: 1;
	width: 14px;
	height: 14px;
	margin-top: -7px;
	color: rgba(0, 0, 0, 0.25);
	line-height: 14px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-time-picker-icon .ant-time-picker-clock-icon,
.ant-time-picker-clear .ant-time-picker-clock-icon {
	display: block;
	color: rgba(0, 0, 0, 0.25);
	line-height: 1;
}
.ant-time-picker-clear {
	z-index: 2;
	background: #fff;
	opacity: 0;
	pointer-events: none;
}
.ant-time-picker-clear:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-time-picker:hover .ant-time-picker-clear {
	opacity: 1;
	pointer-events: auto;
}
.ant-time-picker-large .ant-time-picker-input {
	height: 40px;
	padding: 6px 11px;
	font-size: 16px;
}
.ant-time-picker-small .ant-time-picker-input {
	height: 24px;
	padding: 1px 7px;
}
.ant-time-picker-small .ant-time-picker-icon,
.ant-time-picker-small .ant-time-picker-clear {
	right: 7px;
}
@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) and (stroke-color: transparent) {
		.ant-input {
			line-height: 1.5;
		}
	}
}

.ant-calendar-picker-container {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: absolute;
	z-index: 1050;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
		"Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
}
.ant-calendar-picker {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	outline: none;
	cursor: text;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}
.ant-calendar-picker-input {
	outline: none;
}
.ant-calendar-picker-input.ant-input {
	line-height: 1.5;
}
.ant-calendar-picker-input.ant-input-sm {
	padding-top: 0;
	padding-bottom: 0;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
	border-color: #40a9ff;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
	border-color: #40a9ff;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
	box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
	position: absolute;
	top: 50%;
	right: 12px;
	z-index: 1;
	width: 14px;
	height: 14px;
	margin-top: -7px;
	font-size: 12px;
	line-height: 14px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-calendar-picker-clear {
	z-index: 2;
	color: rgba(0, 0, 0, 0.25);
	font-size: 14px;
	background: #fff;
	cursor: pointer;
	opacity: 0;
	pointer-events: none;
}
.ant-calendar-picker-clear:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-calendar-picker:hover .ant-calendar-picker-clear {
	opacity: 1;
	pointer-events: auto;
}
.ant-calendar-picker-icon {
	display: inline-block;
	color: rgba(0, 0, 0, 0.25);
	font-size: 14px;
	line-height: 1;
}
.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
	right: 8px;
}
.ant-calendar {
	position: relative;
	width: 280px;
	font-size: 14px;
	line-height: 1.5;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #fff;
	border-radius: 4px;
	outline: none;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-calendar-input-wrap {
	height: 34px;
	padding: 6px 10px;
	border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-input {
	width: 100%;
	height: 22px;
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
	border: 0;
	outline: 0;
	cursor: auto;
}
.ant-calendar-input::-moz-placeholder {
	color: #bfbfbf;
	opacity: 1;
}
.ant-calendar-input:-ms-input-placeholder {
	color: #bfbfbf;
}
.ant-calendar-input::-webkit-input-placeholder {
	color: #bfbfbf;
}
.ant-calendar-input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-calendar-week-number {
	width: 286px;
}
.ant-calendar-week-number-cell {
	text-align: center;
}
.ant-calendar-header {
	height: 40px;
	line-height: 40px;
	text-align: center;
	border-bottom: 1px solid #e8e8e8;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-calendar-header a:hover {
	color: #40a9ff;
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
	display: inline-block;
	padding: 0 2px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	line-height: 40px;
}
.ant-calendar-header .ant-calendar-century-select-arrow,
.ant-calendar-header .ant-calendar-decade-select-arrow,
.ant-calendar-header .ant-calendar-year-select-arrow,
.ant-calendar-header .ant-calendar-month-select-arrow {
	display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
	position: absolute;
	top: 0;
	display: inline-block;
	padding: 0 5px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 16px;
	font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
		"Microsoft Sans Serif", sans-serif;
	line-height: 40px;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
	left: 7px;
	height: 100%;
}
.ant-calendar-header .ant-calendar-prev-century-btn::before,
.ant-calendar-header .ant-calendar-prev-decade-btn::before,
.ant-calendar-header .ant-calendar-prev-year-btn::before,
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-header .ant-calendar-prev-century-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-century-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
	display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
	position: relative;
	left: -3px;
	display: inline-block;
}
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
	right: 7px;
	height: 100%;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-header .ant-calendar-next-century-btn:hover::before,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-next-year-btn:hover::before,
.ant-calendar-header .ant-calendar-next-century-btn:hover::after,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-next-year-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
	display: none;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
	-webkit-transform: rotate(135deg) scale(0.8);
	-ms-transform: rotate(135deg) scale(0.8);
	transform: rotate(135deg) scale(0.8);
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before {
	position: relative;
	left: 3px;
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
	display: inline-block;
}
.ant-calendar-header .ant-calendar-prev-month-btn {
	left: 29px;
	height: 100%;
}
.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-header .ant-calendar-prev-month-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-month-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-prev-month-btn::after {
	display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn {
	right: 29px;
	height: 100%;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-header .ant-calendar-next-month-btn:hover::before,
.ant-calendar-header .ant-calendar-next-month-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-next-month-btn::after {
	display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
	-webkit-transform: rotate(135deg) scale(0.8);
	-ms-transform: rotate(135deg) scale(0.8);
	transform: rotate(135deg) scale(0.8);
}
.ant-calendar-body {
	padding: 8px 12px;
}
.ant-calendar table {
	width: 100%;
	max-width: 100%;
	background-color: transparent;
	border-collapse: collapse;
}
.ant-calendar table,
.ant-calendar th,
.ant-calendar td {
	text-align: center;
	border: 0;
}
.ant-calendar-calendar-table {
	margin-bottom: 0;
	border-spacing: 0;
}
.ant-calendar-column-header {
	width: 33px;
	padding: 6px 0;
	line-height: 18px;
	text-align: center;
}
.ant-calendar-column-header .ant-calendar-column-header-inner {
	display: block;
	font-weight: normal;
}
.ant-calendar-week-number-header .ant-calendar-column-header-inner {
	display: none;
}
.ant-calendar-cell {
	height: 30px;
	padding: 3px 0;
}
.ant-calendar-date {
	display: block;
	width: 24px;
	height: 24px;
	margin: 0 auto;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	line-height: 22px;
	text-align: center;
	background: transparent;
	border: 1px solid transparent;
	border-radius: 2px;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}
.ant-calendar-date-panel {
	position: relative;
	outline: none;
	z-index: 99999999999;
}
.ant-calendar-date:hover {
	background: #e6f7ff;
	cursor: pointer;
}
.ant-calendar-date:active {
	color: #fff;
	background: #40a9ff;
}
.ant-calendar-today .ant-calendar-date {
	color: #1890ff;
	font-weight: bold;
	border-color: #1890ff;
}
.ant-calendar-selected-day .ant-calendar-date {
	background: #bae7ff;
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date,
.ant-calendar-last-month-cell .ant-calendar-date:hover,
.ant-calendar-next-month-btn-day .ant-calendar-date:hover {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	border-color: transparent;
}
.ant-calendar-disabled-cell .ant-calendar-date {
	position: relative;
	width: auto;
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border: 1px solid transparent;
	border-radius: 0;
	cursor: not-allowed;
}
.ant-calendar-disabled-cell .ant-calendar-date:hover {
	background: #f5f5f5;
}
.ant-calendar-disabled-cell.ant-calendar-selected-day
	.ant-calendar-date::before {
	position: absolute;
	top: -1px;
	left: 5px;
	width: 24px;
	height: 24px;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	content: "";
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
	position: relative;
	padding-right: 5px;
	padding-left: 5px;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
	position: absolute;
	top: -1px;
	left: 5px;
	width: 24px;
	height: 24px;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 2px;
	content: " ";
}
.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.ant-calendar-footer {
	padding: 0 12px;
	line-height: 38px;
	border-top: 1px solid #e8e8e8;
}
.ant-calendar-footer:empty {
	border-top: 0;
}
.ant-calendar-footer-btn {
	display: block;
	text-align: center;
}
.ant-calendar-footer-extra {
	text-align: left;
}
.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
	display: inline-block;
	margin: 0 0 0 8px;
	text-align: center;
}
.ant-calendar .ant-calendar-today-btn-disabled,
.ant-calendar .ant-calendar-clear-btn-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-calendar .ant-calendar-today-btn:only-child,
.ant-calendar .ant-calendar-clear-btn:only-child {
	margin: 0;
}
.ant-calendar .ant-calendar-clear-btn {
	position: absolute;
	top: 7px;
	right: 5px;
	display: none;
	width: 20px;
	height: 20px;
	margin: 0;
	overflow: hidden;
	line-height: 20px;
	text-align: center;
	text-indent: -76px;
}
.ant-calendar .ant-calendar-clear-btn::after {
	display: inline-block;
	width: 20px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 14px;
	line-height: 1;
	text-indent: 43px;
	-webkit-transition: color 0.3s ease;
	transition: color 0.3s ease;
}
.ant-calendar .ant-calendar-clear-btn:hover::after {
	color: rgba(0, 0, 0, 0.45);
}
.ant-calendar .ant-calendar-ok-btn {
	position: relative;
	display: inline-block;
	font-weight: 400;
	white-space: nowrap;
	text-align: center;
	background-image: none;
	border: 1px solid transparent;
	-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	cursor: pointer;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	height: 32px;
	padding: 0 15px;
	color: #fff;
	background-color: #1890ff;
	border-color: #1890ff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
	height: 24px;
	padding: 0 7px;
	font-size: 14px;
	border-radius: 4px;
	line-height: 22px;
}
.ant-calendar .ant-calendar-ok-btn > .anticon {
	line-height: 1;
}
.ant-calendar .ant-calendar-ok-btn,
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn:focus {
	outline: 0;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
	text-decoration: none;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled] {
	cursor: not-allowed;
}
.ant-calendar .ant-calendar-ok-btn.disabled > *,
.ant-calendar .ant-calendar-ok-btn[disabled] > * {
	pointer-events: none;
}
.ant-calendar .ant-calendar-ok-btn-lg {
	height: 40px;
	padding: 0 15px;
	font-size: 16px;
	border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn-sm {
	height: 24px;
	padding: 0 7px;
	font-size: 14px;
	border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child {
	color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
	color: #fff;
	background-color: #40a9ff;
	border-color: #40a9ff;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
	color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn.active {
	color: #fff;
	background-color: #096dd9;
	border-color: #096dd9;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child {
	color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
	color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	text-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
	color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}
.ant-calendar-range-picker-input {
	width: 44%;
	height: 99%;
	text-align: center;
	background-color: transparent;
	border: 0;
	outline: 0;
}
.ant-calendar-range-picker-input::-moz-placeholder {
	color: #bfbfbf;
	opacity: 1;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
	color: #bfbfbf;
}
.ant-calendar-range-picker-input::-webkit-input-placeholder {
	color: #bfbfbf;
}
.ant-calendar-range-picker-input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-calendar-range-picker-input[disabled] {
	cursor: not-allowed;
}
.ant-calendar-range-picker-separator {
	display: inline-block;
	min-width: 10px;
	height: 100%;
	color: rgba(0, 0, 0, 0.45);
	white-space: nowrap;
	text-align: center;
	vertical-align: top;
	pointer-events: none;
}
.ant-calendar-range {
	width: 552px;
	overflow: hidden;
}
.ant-calendar-range .ant-calendar-date-panel::after {
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
	content: ".";
}
.ant-calendar-range-part {
	position: relative;
	width: 50%;
}
.ant-calendar-range-left {
	float: left;
}
.ant-calendar-range-left .ant-calendar-time-picker-inner {
	border-right: 1px solid #e8e8e8;
}
.ant-calendar-range-right {
	float: right;
}
.ant-calendar-range-right .ant-calendar-time-picker-inner {
	border-left: 1px solid #e8e8e8;
}
.ant-calendar-range-middle {
	position: absolute;
	left: 50%;
	z-index: 1;
	height: 34px;
	margin: 1px 0 0 0;
	padding: 0 200px 0 0;
	color: rgba(0, 0, 0, 0.45);
	line-height: 34px;
	text-align: center;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	pointer-events: none;
}
.ant-calendar-range-right .ant-calendar-date-input-wrap {
	margin-left: -90px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
	padding: 0 10px 0 0;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}
.ant-calendar-range
	.ant-calendar-today
	:not(.ant-calendar-disabled-cell)
	:not(.ant-calendar-last-month-cell)
	:not(.ant-calendar-next-month-btn-day)
	.ant-calendar-date {
	color: #1890ff;
	background: #bae7ff;
	border-color: #1890ff;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
	color: #fff;
	background: #1890ff;
	border: 1px solid transparent;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
	background: #1890ff;
}
.ant-calendar-range.ant-calendar-time
	.ant-calendar-range-right
	.ant-calendar-date-input-wrap {
	margin-left: 0;
}
.ant-calendar-range .ant-calendar-input-wrap {
	position: relative;
	height: 34px;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 32px;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	height: 24px;
	padding-right: 0;
	padding-left: 0;
	line-height: 24px;
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
	color: #bfbfbf;
	opacity: 1;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
	color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
	color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input:placeholder-shown,
.ant-calendar-range .ant-calendar-time-picker-input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
	border-color: #40a9ff;
	border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
	border-color: #40a9ff;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
	box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input[disabled],
.ant-calendar-range .ant-calendar-time-picker-input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-calendar-range .ant-calendar-input[disabled]:hover,
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
textarea.ant-calendar-range .ant-calendar-input,
textarea.ant-calendar-range .ant-calendar-time-picker-input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5;
	vertical-align: bottom;
	-webkit-transition: all 0.3s, height 0s;
	transition: all 0.3s, height 0s;
}
.ant-calendar-range .ant-calendar-input-lg,
.ant-calendar-range .ant-calendar-time-picker-input-lg {
	height: 40px;
	padding: 6px 11px;
	font-size: 16px;
}
.ant-calendar-range .ant-calendar-input-sm,
.ant-calendar-range .ant-calendar-time-picker-input-sm {
	height: 24px;
	padding: 1px 7px;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-calendar-range .ant-calendar-time-picker-icon {
	display: none;
}
.ant-calendar-range.ant-calendar-week-number {
	width: 574px;
}
.ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
	width: 286px;
}
.ant-calendar-range .ant-calendar-year-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-decade-panel {
	top: 34px;
}
.ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
	top: 0;
}
.ant-calendar-range .ant-calendar-decade-panel-table,
.ant-calendar-range .ant-calendar-year-panel-table,
.ant-calendar-range .ant-calendar-month-panel-table {
	height: 208px;
}
.ant-calendar-range .ant-calendar-in-range-cell {
	position: relative;
	border-radius: 0;
}
.ant-calendar-range .ant-calendar-in-range-cell > div {
	position: relative;
	z-index: 1;
}
.ant-calendar-range .ant-calendar-in-range-cell::before {
	position: absolute;
	top: 4px;
	right: 0;
	bottom: 4px;
	left: 0;
	display: block;
	background: #e6f7ff;
	border: 0;
	border-radius: 0;
	content: "";
}
.ant-calendar-range .ant-calendar-footer-extra {
	float: left;
}
div.ant-calendar-range-quick-selector {
	text-align: left;
}
div.ant-calendar-range-quick-selector > a {
	margin-right: 8px;
}
.ant-calendar-range .ant-calendar-header,
.ant-calendar-range .ant-calendar-month-panel-header,
.ant-calendar-range .ant-calendar-year-panel-header,
.ant-calendar-range .ant-calendar-decade-panel-header {
	border-bottom: 0;
}
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body,
.ant-calendar-range .ant-calendar-decade-panel-body {
	border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
	top: 68px;
	z-index: 2;
	width: 100%;
	height: 207px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
	height: 267px;
	margin-top: -34px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
	height: 100%;
	padding-top: 40px;
	background: none;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
	display: inline-block;
	height: 100%;
	background-color: #fff;
	border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
	height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
	max-height: 100%;
}
.ant-calendar-range.ant-calendar-time
	.ant-calendar-footer
	.ant-calendar-time-picker-btn {
	margin-right: 8px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
	height: 22px;
	margin: 8px 12px;
	line-height: 22px;
}
.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
	height: 233px;
}
.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
	border-top-color: transparent;
}
.ant-calendar-time-picker {
	position: absolute;
	top: 40px;
	width: 100%;
	background-color: #fff;
}
.ant-calendar-time-picker-panel {
	position: absolute;
	z-index: 1050;
	width: 100%;
}
.ant-calendar-time-picker-inner {
	position: relative;
	display: inline-block;
	width: 100%;
	overflow: hidden;
	font-size: 14px;
	line-height: 1.5;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	outline: none;
}
.ant-calendar-time-picker-combobox {
	width: 100%;
}
.ant-calendar-time-picker-column-1,
.ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
	width: 100%;
}
.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
	width: 50%;
}
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
	width: 33.33%;
}
.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
	width: 25%;
}
.ant-calendar-time-picker-input-wrap {
	display: none;
}
.ant-calendar-time-picker-select {
	position: relative;
	float: left;
	height: 226px;
	overflow: hidden;
	font-size: 14px;
	border-right: 1px solid #e8e8e8;
}
.ant-calendar-time-picker-select:hover {
	overflow-y: auto;
}
.ant-calendar-time-picker-select:first-child {
	margin-left: 0;
	border-left: 0;
}
.ant-calendar-time-picker-select:last-child {
	border-right: 0;
}
.ant-calendar-time-picker-select ul {
	width: 100%;
	max-height: 206px;
	margin: 0;
	padding: 0;
	list-style: none;
}
.ant-calendar-time-picker-select li {
	width: 100%;
	height: 24px;
	margin: 0;
	line-height: 24px;
	text-align: center;
	list-style: none;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-calendar-time-picker-select li:last-child::after {
	display: block;
	height: 202px;
	content: "";
}
.ant-calendar-time-picker-select li:hover {
	background: #e6f7ff;
}
.ant-calendar-time-picker-select li:focus {
	color: #1890ff;
	font-weight: 600;
	outline: none;
}
li.ant-calendar-time-picker-select-option-selected {
	font-weight: 600;
	background: #f5f5f5;
}
li.ant-calendar-time-picker-select-option-disabled {
	color: rgba(0, 0, 0, 0.25);
}
li.ant-calendar-time-picker-select-option-disabled:hover {
	background: transparent;
	cursor: not-allowed;
}
.ant-calendar-time .ant-calendar-day-select {
	display: inline-block;
	padding: 0 2px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	line-height: 34px;
}
.ant-calendar-time .ant-calendar-footer {
	position: relative;
	height: auto;
}
.ant-calendar-time .ant-calendar-footer-btn {
	text-align: right;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
	float: left;
	margin: 0;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
	display: inline-block;
	margin-right: 8px;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
	color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-month-panel {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	background: #fff;
	border-radius: 4px;
	outline: none;
}
.ant-calendar-month-panel > div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100%;
}
.ant-calendar-month-panel-hidden {
	display: none;
}
.ant-calendar-month-panel-header {
	height: 40px;
	line-height: 40px;
	text-align: center;
	border-bottom: 1px solid #e8e8e8;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
}
.ant-calendar-month-panel-header a:hover {
	color: #40a9ff;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
	display: inline-block;
	padding: 0 2px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
	display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
	position: absolute;
	top: 0;
	display: inline-block;
	padding: 0 5px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 16px;
	font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
		"Microsoft Sans Serif", sans-serif;
	line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
	left: 7px;
	height: 100%;
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-century-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-decade-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-year-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-year-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-century-btn:hover::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-decade-btn:hover::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-year-btn:hover::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-century-btn:hover::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-decade-btn:hover::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-year-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-year-btn::after {
	display: none;
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-year-btn::after {
	position: relative;
	left: -3px;
	display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
	right: 7px;
	height: 100%;
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-year-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-century-btn:hover::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-decade-btn:hover::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-year-btn:hover::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-century-btn:hover::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-decade-btn:hover::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-year-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-year-btn::after {
	display: none;
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-year-btn::after {
	-webkit-transform: rotate(135deg) scale(0.8);
	-ms-transform: rotate(135deg) scale(0.8);
	transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-year-btn::before {
	position: relative;
	left: 3px;
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-year-btn::after {
	display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
	left: 29px;
	height: 100%;
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-month-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-month-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-month-btn:hover::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-month-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-prev-month-btn::after {
	display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
	right: 29px;
	height: 100%;
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-month-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-month-btn:hover::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-month-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-month-btn::after {
	display: none;
}
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header
	.ant-calendar-month-panel-next-month-btn::after {
	-webkit-transform: rotate(135deg) scale(0.8);
	-ms-transform: rotate(135deg) scale(0.8);
	transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-body {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.ant-calendar-month-panel-footer {
	border-top: 1px solid #e8e8e8;
}
.ant-calendar-month-panel-footer .ant-calendar-footer-extra {
	padding: 0 12px;
}
.ant-calendar-month-panel-table {
	width: 100%;
	height: 100%;
	table-layout: fixed;
	border-collapse: separate;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
	color: #fff;
	background: #1890ff;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
	color: #fff;
	background: #1890ff;
}
.ant-calendar-month-panel-cell {
	text-align: center;
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	cursor: not-allowed;
}
.ant-calendar-month-panel-month {
	display: inline-block;
	height: 24px;
	margin: 0 auto;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.65);
	line-height: 24px;
	text-align: center;
	background: transparent;
	border-radius: 2px;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}
.ant-calendar-month-panel-month:hover {
	background: #e6f7ff;
	cursor: pointer;
}
.ant-calendar-year-panel {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	background: #fff;
	border-radius: 4px;
	outline: none;
}
.ant-calendar-year-panel > div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100%;
}
.ant-calendar-year-panel-hidden {
	display: none;
}
.ant-calendar-year-panel-header {
	height: 40px;
	line-height: 40px;
	text-align: center;
	border-bottom: 1px solid #e8e8e8;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
}
.ant-calendar-year-panel-header a:hover {
	color: #40a9ff;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
	display: inline-block;
	padding: 0 2px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
	display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
	position: absolute;
	top: 0;
	display: inline-block;
	padding: 0 5px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 16px;
	font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
		"Microsoft Sans Serif", sans-serif;
	line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
	left: 7px;
	height: 100%;
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-century-btn::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-century-btn:hover::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-decade-btn:hover::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-year-btn:hover::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-century-btn:hover::after,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-decade-btn:hover::after,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-year-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
	display: none;
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
	position: relative;
	left: -3px;
	display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
	right: 7px;
	height: 100%;
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-century-btn:hover::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-decade-btn:hover::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-year-btn:hover::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-century-btn:hover::after,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-decade-btn:hover::after,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-year-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
	display: none;
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
	-webkit-transform: rotate(135deg) scale(0.8);
	-ms-transform: rotate(135deg) scale(0.8);
	transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before {
	position: relative;
	left: 3px;
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
	display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
	left: 29px;
	height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-month-btn:hover::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-prev-month-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
	display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
	right: 29px;
	height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-month-btn:hover::before,
.ant-calendar-year-panel-header
	.ant-calendar-year-panel-next-month-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
	display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
	-webkit-transform: rotate(135deg) scale(0.8);
	-ms-transform: rotate(135deg) scale(0.8);
	transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-body {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.ant-calendar-year-panel-footer {
	border-top: 1px solid #e8e8e8;
}
.ant-calendar-year-panel-footer .ant-calendar-footer-extra {
	padding: 0 12px;
}
.ant-calendar-year-panel-table {
	width: 100%;
	height: 100%;
	table-layout: fixed;
	border-collapse: separate;
}
.ant-calendar-year-panel-cell {
	text-align: center;
}
.ant-calendar-year-panel-year {
	display: inline-block;
	height: 24px;
	margin: 0 auto;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.65);
	line-height: 24px;
	text-align: center;
	background: transparent;
	border-radius: 2px;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}
.ant-calendar-year-panel-year:hover {
	background: #e6f7ff;
	cursor: pointer;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
	color: #fff;
	background: #1890ff;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
	color: #fff;
	background: #1890ff;
}
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
	color: rgba(0, 0, 0, 0.25);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-calendar-decade-panel {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	background: #fff;
	border-radius: 4px;
	outline: none;
}
.ant-calendar-decade-panel-hidden {
	display: none;
}
.ant-calendar-decade-panel-header {
	height: 40px;
	line-height: 40px;
	text-align: center;
	border-bottom: 1px solid #e8e8e8;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
}
.ant-calendar-decade-panel-header a:hover {
	color: #40a9ff;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
	display: inline-block;
	padding: 0 2px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	line-height: 40px;
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-century-select-arrow,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-decade-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-month-select-arrow {
	display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
	position: absolute;
	top: 0;
	display: inline-block;
	padding: 0 5px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 16px;
	font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
		"Microsoft Sans Serif", sans-serif;
	line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
	left: 7px;
	height: 100%;
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-century-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-decade-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-year-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-year-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-century-btn:hover::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-decade-btn:hover::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-year-btn:hover::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-century-btn:hover::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-decade-btn:hover::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-year-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-year-btn::after {
	display: none;
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-year-btn::after {
	position: relative;
	left: -3px;
	display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
	right: 7px;
	height: 100%;
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-year-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-century-btn:hover::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-decade-btn:hover::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-year-btn:hover::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-century-btn:hover::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-decade-btn:hover::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-year-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-year-btn::after {
	display: none;
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-year-btn::after {
	-webkit-transform: rotate(135deg) scale(0.8);
	-ms-transform: rotate(135deg) scale(0.8);
	transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-year-btn::before {
	position: relative;
	left: 3px;
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-year-btn::after {
	display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
	left: 29px;
	height: 100%;
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-month-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-month-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-month-btn:hover::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-month-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-prev-month-btn::after {
	display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
	right: 29px;
	height: 100%;
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-month-btn::after {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	vertical-align: middle;
	border: 0 solid #aaa;
	border-width: 1.5px 0 0 1.5px;
	border-radius: 1px;
	-webkit-transform: rotate(-45deg) scale(0.8);
	-ms-transform: rotate(-45deg) scale(0.8);
	transform: rotate(-45deg) scale(0.8);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-month-btn:hover::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-month-btn:hover::after {
	border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-month-btn::after {
	display: none;
}
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header
	.ant-calendar-decade-panel-next-month-btn::after {
	-webkit-transform: rotate(135deg) scale(0.8);
	-ms-transform: rotate(135deg) scale(0.8);
	transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-body {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.ant-calendar-decade-panel-footer {
	border-top: 1px solid #e8e8e8;
}
.ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
	padding: 0 12px;
}
.ant-calendar-decade-panel-table {
	width: 100%;
	height: 100%;
	table-layout: fixed;
	border-collapse: separate;
}
.ant-calendar-decade-panel-cell {
	white-space: nowrap;
	text-align: center;
}
.ant-calendar-decade-panel-decade {
	display: inline-block;
	height: 24px;
	margin: 0 auto;
	padding: 0 6px;
	color: rgba(0, 0, 0, 0.65);
	line-height: 24px;
	text-align: center;
	background: transparent;
	border-radius: 2px;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}
.ant-calendar-decade-panel-decade:hover {
	background: #e6f7ff;
	cursor: pointer;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
	color: #fff;
	background: #1890ff;
}
.ant-calendar-decade-panel-selected-cell
	.ant-calendar-decade-panel-decade:hover {
	color: #fff;
	background: #1890ff;
}
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
	color: rgba(0, 0, 0, 0.25);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-calendar-month .ant-calendar-month-header-wrap {
	position: relative;
	height: 288px;
}
.ant-calendar-month .ant-calendar-month-panel,
.ant-calendar-month .ant-calendar-year-panel {
	top: 0;
	height: 100%;
}
.ant-calendar-week-number-cell {
	opacity: 0.5;
}
.ant-calendar-week-number .ant-calendar-body tr {
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
	background: #e6f7ff;
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
	font-weight: bold;
	background: #bae7ff;
}
.ant-calendar-week-number
	.ant-calendar-body
	tr
	.ant-calendar-selected-day
	.ant-calendar-date,
.ant-calendar-week-number
	.ant-calendar-body
	tr
	.ant-calendar-selected-day:hover
	.ant-calendar-date {
	color: rgba(0, 0, 0, 0.65);
	background: transparent;
}

/* ant table */
.ant-table-wrapper {
	zoom: 1;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
	display: table;
	content: "";
}
.ant-table-wrapper::after {
	clear: both;
}
.ant-table {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	clear: both;
}
.ant-table-body {
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
	overflow-x: auto !important;
	overflow-y: hidden !important;
}
.ant-table table {
	width: 100%;
	text-align: left;
	border-radius: 4px 4px 0 0;
	border-collapse: separate;
	border-spacing: 0;
}
.ant-table-thead > tr > th {
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	text-align: left;
	background: #fafafa;
	border-bottom: 1px solid #e8e8e8;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan] {
	text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
	position: absolute;
	top: 0;
	right: 0;
	width: 28px;
	height: 100%;
	color: #bfbfbf;
	font-size: 12px;
	text-align: center;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -5px;
	margin-left: -6px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
	color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter {
	display: table-cell;
	vertical-align: middle;
}
.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner {
	height: 1em;
	margin-top: 0.35em;
	margin-left: 0.57142857em;
	color: #bfbfbf;
	line-height: 1em;
	text-align: center;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner
	.ant-table-column-sorter-up,
.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner
	.ant-table-column-sorter-down {
	display: inline-block;
	font-size: 12px;
	font-size: 11px \9;
	-webkit-transform: scale(0.91666667) rotate(0deg);
	-ms-transform: scale(0.91666667) rotate(0deg);
	transform: scale(0.91666667) rotate(0deg);
	display: block;
	height: 1em;
	line-height: 1em;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
:root
	.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner
	.ant-table-column-sorter-up,
:root
	.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner
	.ant-table-column-sorter-down {
	font-size: 12px;
}
.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner
	.ant-table-column-sorter-up.on,
.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner
	.ant-table-column-sorter-down.on {
	color: #1890ff;
}
.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner-full {
	margin-top: -0.15em;
}
.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner-full
	.ant-table-column-sorter-up,
.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner-full
	.ant-table-column-sorter-down {
	height: 0.5em;
	line-height: 0.5em;
}
.ant-table-thead
	> tr
	> th
	.ant-table-column-sorter
	.ant-table-column-sorter-inner-full
	.ant-table-column-sorter-down {
	margin-top: 0.125em;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
	position: relative;
	background-clip: padding-box;
	/* stylelint-disable-next-line */
	-webkit-background-clip: border-box;
}
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-filters {
	padding-right: 30px !important;
}
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-filters
	.anticon-filter.ant-table-filter-open,
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-filters
	.ant-table-filter-icon.ant-table-filter-open {
	color: rgba(0, 0, 0, 0.45);
	background: #e5e5e5;
}
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-filters:hover
	.anticon-filter:hover,
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-filters:hover
	.ant-table-filter-icon:hover {
	color: rgba(0, 0, 0, 0.45);
	background: #e5e5e5;
}
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-filters:hover
	.anticon-filter:active,
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-filters:hover
	.ant-table-filter-icon:active {
	color: rgba(0, 0, 0, 0.65);
}
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-sorters {
	cursor: pointer;
}
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
	background: #f2f2f2;
}
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
	.anticon-filter,
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
	.ant-table-filter-icon {
	background: #f2f2f2;
}
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-sorters:active
	.ant-table-column-sorter-up:not(.on),
.ant-table-thead
	> tr
	> th.ant-table-column-has-actions.ant-table-column-has-sorters:active
	.ant-table-column-sorter-down:not(.on) {
	color: rgba(0, 0, 0, 0.45);
}
.ant-table-thead > tr > th .ant-table-header-column {
	display: inline-block;
	vertical-align: top;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
	display: table;
}
.ant-table-thead
	> tr
	> th
	.ant-table-header-column
	.ant-table-column-sorters
	> .ant-table-column-title {
	display: table-cell;
	vertical-align: middle;
}
.ant-table-thead
	> tr
	> th
	.ant-table-header-column
	.ant-table-column-sorters
	> *:not(.ant-table-column-sorter) {
	position: relative;
}
.ant-table-thead
	> tr
	> th
	.ant-table-header-column
	.ant-table-column-sorters::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	content: "";
}
.ant-table-thead
	> tr
	> th
	.ant-table-header-column
	.ant-table-column-sorters:hover::before {
	background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-sorters {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-table-thead > tr:first-child > th:first-child {
	border-top-left-radius: 4px;
}
.ant-table-thead > tr:first-child > th:last-child {
	border-top-right-radius: 4px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
	border-bottom: 0;
}
.ant-table-tbody > tr > td {
	border-bottom: 1px solid #e8e8e8;
	-webkit-transition: all 0.3s, border 0s;
	transition: all 0.3s, border 0s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
	-webkit-transition: all 0.3s, height 0s;
	transition: all 0.3s, height 0s;
}
.ant-table-thead
	> tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
	> td,
.ant-table-tbody
	> tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
	> td,
.ant-table-thead
	> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
	> td,
.ant-table-tbody
	> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
	> td {
	background: #e6f7ff;
}
.ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
	background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td,
.ant-table-tbody > tr:hover.ant-table-row-selected > td {
	background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr:hover.ant-table-row-selected > td.ant-table-column-sort {
	background: #fafafa;
}
.ant-table-thead > tr:hover {
	background: none;
}
.ant-table-footer {
	position: relative;
	padding: 16px 16px;
	color: rgba(0, 0, 0, 0.85);
	background: #fafafa;
	border-top: 1px solid #e8e8e8;
	border-radius: 0 0 4px 4px;
}
.ant-table-footer::before {
	position: absolute;
	top: -1px;
	left: 0;
	width: 100%;
	height: 1px;
	background: #fafafa;
	content: "";
}
.ant-table.ant-table-bordered .ant-table-footer {
	border: 1px solid #e8e8e8;
}
.ant-table-title {
	position: relative;
	top: 1px;
	padding: 16px 0;
	border-radius: 4px 4px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
	padding-right: 16px;
	padding-left: 16px;
	border: 1px solid #e8e8e8;
}
.ant-table-title + .ant-table-content {
	position: relative;
	border-radius: 4px 4px 0 0;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered
	.ant-table-title
	+ .ant-table-content
	.ant-table-thead
	> tr:first-child
	> th {
	border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
	border-radius: 0;
}
.ant-table-without-column-header.ant-table-bordered.ant-table-empty
	.ant-table-placeholder {
	border-top: 1px solid #e8e8e8;
	border-radius: 4px;
}
.ant-table-tbody > tr.ant-table-row-selected td {
	color: inherit;
	background: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort {
	background: #f5f5f5;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
	background: rgba(0, 0, 0, 0.01);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	padding: 16px 16px;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
	width: 50px;
	min-width: 50px;
	text-align: center;
}
.ant-table-header {
	overflow: hidden;
	background: #fafafa;
}
.ant-table-header table {
	border-radius: 4px 4px 0 0;
}
.ant-table-loading {
	position: relative;
}
.ant-table-loading .ant-table-body {
	background: #fff;
	opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 20px;
	margin-left: -30px;
	line-height: 20px;
}
.ant-table-loading .ant-table-with-pagination {
	margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
	margin-top: 10px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
	border: 1px solid #e8e8e8;
	border-right: 0;
	border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
	border-right: 1px solid #e8e8e8;
	border-left: 1px solid #e8e8e8;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
	border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header
	.ant-table-header
	+ .ant-table-body
	> table,
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
	border-top: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
	border-bottom: 1px solid #e8e8e8;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
	border-right: 1px solid #e8e8e8;
}
.ant-table-placeholder {
	position: relative;
	z-index: 1;
	margin-top: -1px;
	padding: 16px 16px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 14px;
	text-align: center;
	background: #fff;
	border-top: 1px solid #e8e8e8;
	border-bottom: 1px solid #e8e8e8;
	border-radius: 0 0 4px 4px;
}
.ant-table-pagination.ant-pagination {
	float: right;
	margin: 16px 0;
}
.ant-table-filter-dropdown {
	position: relative;
	min-width: 96px;
	margin-left: -8px;
	background: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
	border: 0;
	border-radius: 4px 4px 0 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
	max-height: 400px;
	overflow-x: hidden;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
	padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
	border-radius: 4px;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown
	.ant-dropdown-menu
	.ant-dropdown-submenu-contain-selected
	.ant-dropdown-menu-submenu-title::after {
	color: #1890ff;
	font-weight: bold;
	text-shadow: 0 0 2px #bae7ff;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
	overflow: hidden;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span {
	padding-left: 8px;
}
.ant-table-filter-dropdown
	> .ant-dropdown-menu
	> .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown
	> .ant-dropdown-menu
	> .ant-dropdown-menu-submenu:last-child
	.ant-dropdown-menu-submenu-title {
	border-radius: 0;
}
.ant-table-filter-dropdown-btns {
	padding: 7px 8px;
	overflow: hidden;
	border-top: 1px solid #e8e8e8;
}
.ant-table-filter-dropdown-link {
	color: #1890ff;
}
.ant-table-filter-dropdown-link:hover {
	color: #40a9ff;
}
.ant-table-filter-dropdown-link:active {
	color: #096dd9;
}
.ant-table-filter-dropdown-link.confirm {
	float: left;
}
.ant-table-filter-dropdown-link.clear {
	float: right;
}
.ant-table-selection {
	white-space: nowrap;
}
.ant-table-selection-select-all-custom {
	margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
	color: #bfbfbf;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-table-selection-menu {
	min-width: 96px;
	margin-top: 5px;
	margin-left: -30px;
	background: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-selection-menu .ant-action-down {
	color: #bfbfbf;
}
.ant-table-selection-down {
	display: inline-block;
	padding: 0;
	line-height: 1;
	cursor: pointer;
}
.ant-table-selection-down:hover .anticon-down {
	color: rgba(0, 0, 0, 0.6);
}
.ant-table-row-expand-icon {
	color: #1890ff;
	text-decoration: none;
	cursor: pointer;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	display: inline-block;
	width: 17px;
	height: 17px;
	color: inherit;
	line-height: 13px;
	text-align: center;
	background: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 2px;
	outline: none;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
	color: #40a9ff;
}
.ant-table-row-expand-icon:active {
	color: #096dd9;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
	border-color: currentColor;
}
.ant-table-row-expanded::after {
	content: "-";
}
.ant-table-row-collapsed::after {
	content: "+";
}
.ant-table-row-spaced {
	visibility: hidden;
}
.ant-table-row-spaced::after {
	content: ".";
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
	background: #fbfbfb;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
	margin: -16px -16px -17px;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
	margin-right: 8px;
}
.ant-table-scroll {
	overflow: auto;
	overflow-x: hidden;
}
.ant-table-scroll table {
	width: auto;
	min-width: 100%;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body {
	visibility: hidden;
}
.ant-table-body-inner {
	height: 100%;
}
.ant-table-fixed-header
	> .ant-table-content
	> .ant-table-scroll
	> .ant-table-body {
	position: relative;
	background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
	overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
	margin-bottom: -20px;
	padding-bottom: 20px;
	overflow: scroll;
	opacity: 0.9999;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
	border: 1px solid #e8e8e8;
	border-width: 0 0 1px 0;
}
.ant-table-hide-scrollbar {
	scrollbar-color: transparent transparent;
}
.ant-table-hide-scrollbar::-webkit-scrollbar {
	background-color: transparent;
}
.ant-table-bordered.ant-table-fixed-header
	.ant-table-scroll
	.ant-table-header::-webkit-scrollbar {
	border: 1px solid #e8e8e8;
	border-width: 1px 1px 1px 0;
}
.ant-table-bordered.ant-table-fixed-header
	.ant-table-scroll
	.ant-table-header.ant-table-hide-scrollbar
	.ant-table-thead
	> tr:only-child
	> th:last-child {
	border-right-color: transparent;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
	position: absolute;
	top: 0;
	z-index: auto;
	overflow: hidden;
	border-radius: 0;
	-webkit-transition: -webkit-box-shadow 0.3s ease;
	transition: -webkit-box-shadow 0.3s ease;
	transition: box-shadow 0.3s ease;
	transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
	width: auto;
	background: #fff;
}
.ant-table-fixed-header
	.ant-table-fixed-left
	.ant-table-body-outer
	.ant-table-fixed,
.ant-table-fixed-header
	.ant-table-fixed-right
	.ant-table-body-outer
	.ant-table-fixed {
	border-radius: 0;
}
.ant-table-fixed-left {
	left: 0;
	-webkit-box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
	box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
	overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
	margin-right: -20px;
	padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
	padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
	border-radius: 4px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
	border-top-right-radius: 0;
}
.ant-table-fixed-right {
	right: 0;
	-webkit-box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
	box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
	border-radius: 0 4px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
	color: transparent;
	pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
	border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-table colgroup > col.ant-table-selection-col {
	width: 60px;
}
.ant-table-thead
	> tr
	> th.ant-table-selection-column-custom
	.ant-table-selection {
	margin-right: -15px;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
	text-align: center;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
	margin-right: 0;
}
.ant-table-row[class*="ant-table-row-level-0"]
	.ant-table-selection-column
	> span {
	display: inline-block;
}

/* ant pagination */
.ant-pagination {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
	margin: 0;
	padding: 0;
	list-style: none;
}
.ant-pagination::after {
	display: block;
	clear: both;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	content: " ";
}
.ant-pagination-total-text {
	display: inline-block;
	height: 32px;
	margin-right: 8px;
	line-height: 30px;
	vertical-align: middle;
}
.ant-pagination-item {
	display: inline-block;
	min-width: 32px;
	height: 32px;
	margin-right: 8px;
	font-family: Arial;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	outline: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-pagination-item a {
	display: block;
	padding: 0 6px;
	color: rgba(0, 0, 0, 0.65);
	-webkit-transition: none;
	transition: none;
}
.ant-pagination-item a:hover {
	text-decoration: none;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
	border-color: #1890ff;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
	color: #1890ff;
}
.ant-pagination-item-active {
	font-weight: 500;
	background: #fff;
	border-color: #1890ff;
}
.ant-pagination-item-active a {
	color: #1890ff;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
	border-color: #40a9ff;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
	color: #40a9ff;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
	outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
	position: relative;
}
.ant-pagination-jump-prev
	.ant-pagination-item-container
	.ant-pagination-item-link-icon,
.ant-pagination-jump-next
	.ant-pagination-item-container
	.ant-pagination-item-link-icon {
	display: inline-block;
	font-size: 12px;
	font-size: 12px \9;
	-webkit-transform: scale(1) rotate(0deg);
	-ms-transform: scale(1) rotate(0deg);
	transform: scale(1) rotate(0deg);
	color: #1890ff;
	letter-spacing: -1px;
	opacity: 0;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}
:root
	.ant-pagination-jump-prev
	.ant-pagination-item-container
	.ant-pagination-item-link-icon,
:root
	.ant-pagination-jump-next
	.ant-pagination-item-container
	.ant-pagination-item-link-icon {
	font-size: 12px;
}
.ant-pagination-jump-prev
	.ant-pagination-item-container
	.ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next
	.ant-pagination-item-container
	.ant-pagination-item-link-icon-svg {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}
.ant-pagination-jump-prev
	.ant-pagination-item-container
	.ant-pagination-item-ellipsis,
.ant-pagination-jump-next
	.ant-pagination-item-container
	.ant-pagination-item-ellipsis {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	margin: auto;
	color: rgba(0, 0, 0, 0.25);
	letter-spacing: 2px;
	text-align: center;
	text-indent: 0.13em;
	opacity: 1;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
	opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
	opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
	margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
	display: inline-block;
	min-width: 32px;
	height: 32px;
	color: rgba(0, 0, 0, 0.65);
	font-family: Arial;
	line-height: 32px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	border-radius: 4px;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
	outline: 0;
}
.ant-pagination-prev a,
.ant-pagination-next a {
	color: rgba(0, 0, 0, 0.65);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
	border-color: #40a9ff;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	display: block;
	height: 100%;
	font-size: 12px;
	text-align: center;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	outline: none;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
	color: #1890ff;
	border-color: #1890ff;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
	cursor: not-allowed;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-pagination-slash {
	margin: 0 10px 0 5px;
}
.ant-pagination-options {
	display: inline-block;
	margin-left: 16px;
	vertical-align: middle;
}
.ant-pagination-options-size-changer.ant-select {
	display: inline-block;
	width: auto;
	margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
	display: inline-block;
	height: 32px;
	line-height: 32px;
	vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 32px;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	width: 50px;
	margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
	color: #bfbfbf;
	opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
	color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
	color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
	text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
	border-color: #40a9ff;
	border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus {
	border-color: #40a9ff;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
	box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-pagination-options-quick-jumper input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5;
	vertical-align: bottom;
	-webkit-transition: all 0.3s, height 0s;
	transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
	height: 40px;
	padding: 6px 11px;
	font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
	height: 24px;
	padding: 1px 7px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
	height: 24px;
	line-height: 24px;
	vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
	height: 24px;
	border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
	height: 24px;
	line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
	display: inline-block;
	height: 24px;
	margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	height: 100%;
	margin-right: 8px;
	padding: 0 6px;
	text-align: center;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	outline: none;
	-webkit-transition: border-color 0.3s;
	transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
	border-color: #1890ff;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
	height: 24px;
	line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
	min-width: 24px;
	height: 24px;
	margin: 0;
	line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
	background: transparent;
	border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
	min-width: 24px;
	height: 24px;
	margin: 0;
	line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
	background: transparent;
	border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
	height: 24px;
	line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
	height: 24px;
	margin-right: 0;
	line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
	margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
	height: 24px;
	line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
	height: 24px;
	padding: 1px 7px;
	width: 44px;
}
.ant-pagination.ant-pagination-disabled {
	cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	border: none;
	cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
	background: #dbdbdb;
	border-color: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
	color: #fff;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:hover,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:focus {
	color: rgba(0, 0, 0, 0.45);
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled
	.ant-pagination-jump-prev:focus
	.ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled
	.ant-pagination-jump-next:focus
	.ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled
	.ant-pagination-jump-prev:hover
	.ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled
	.ant-pagination-jump-next:hover
	.ant-pagination-item-link-icon {
	opacity: 0;
}
.ant-pagination.ant-pagination-disabled
	.ant-pagination-jump-prev:focus
	.ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled
	.ant-pagination-jump-next:focus
	.ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled
	.ant-pagination-jump-prev:hover
	.ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled
	.ant-pagination-jump-next:hover
	.ant-pagination-item-ellipsis {
	opacity: 1;
}
@media only screen and (max-width: 992px) {
	.ant-pagination-item-after-jump-prev,
	.ant-pagination-item-before-jump-next {
		display: none;
	}
}
@media only screen and (max-width: 576px) {
	.ant-pagination-options {
		display: none;
	}
}

.box-point-tl {
	border: 2px solid #f4c414;
	padding: 10px 20px;
	border-radius: 10px;
	background: #f4c414;
	width: 500px;
}

/* page not found */
.fundo {
	animation: scales 3s alternate infinite;
	transform-origin: center;
}
.pao-baixo {
	animation: rotatepao 14s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
	transform-origin: center;
}
.pao-cima {
	animation: rotatepao 7s 1s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
	transform-origin: center;
}
.olhos {
	animation: olhos 2s alternate infinite;
	transform-origin: center;
}

.left-sparks {
	animation: left-sparks 4s alternate infinite;
	transform-origin: 150px 156px;
}

.right-sparks {
	animation: left-sparks 4s alternate infinite;
	transform-origin: 310px 150px;
}

.olhos {
	animation: olhos 2s alternate infinite;
	transform-origin: center;
}
@keyframes scales {
	from {
		transform: scale(0.98);
	}
	to {
		transform: scale(1);
	}
}

@keyframes rotatepao {
	0% {
		transform: rotate(0deg);
	}
	50%,
	60% {
		transform: rotate(-20deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes olhos {
	0% {
		transform: rotateX(0deg);
	}
	100% {
		transform: rotateX(30deg);
	}
}

@keyframes left-sparks {
	0% {
		opacity: 0;
	}
}

.main {
	min-height: 600px;
	margin: 0px auto;
	width: auto;
	max-width: 460px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.path {
	stroke-dasharray: 300;
	stroke-dashoffset: 300;
	animation: dash 4s alternate infinite;
}

@keyframes dash {
	0%,
	30% {
		fill: 4b4b62;
		stroke-dashoffset: 0;
	}
	80%,
	100% {
		fill: transparent;
		stroke-dashoffset: -200;
	}
}


.ant-select{
	width: 100%;
}

.chapter-item{
	margin: 20px 0px;
	overflow: hidden;
}

.chapter-item .chapter-head{
    background: #f5f5f5;
    font-weight: 500;
    padding: 10px;
	border-radius: 10px;
	position: relative;
}

.chapter-head .remove_chapter {
    cursor: pointer;
    top: 3px;
    right: 21px;
    position: absolute;
}

.add-lesson {
float: right;
}

.category-item {
	display: flex;
	padding: 12px 20px;
}
.category-name {
    width: 50%;
}
.publish_at {
    width: 20%;
}
.category-delete {
	width: 30%;
	text-align: right;
}

.add-category{
	display: flex;width: 600px;float: right;
	margin: 20px 0px;
}

.ordering{
width: 120px;
    position: absolute;
    right: 100px;
    top: 4px;
}

.chapter-head,.category-item{
	position: relative;
}

.category-item .ordering{
	top: 14px;
}
.chuongtrinh_title>span{
	display: inline-block;
}
.chuongtrinh_title>span:nth-child(1){
	width: 45%;
}

.chuongtrinh_title>span:nth-child(2){
	width: 30%;
	font-weight: 700;
}

.chuongtrinh_title>span:nth-child(3){
	width: 15%;
	font-weight: 700;
	text-align: center;
}

.updatePos {
	cursor: pointer;
	font-size: 15px;
}

.updatePos:hover{
	color: #1890ff;
}


.category-position {
	background-color: #31c971;
    display: inline-table;
    width: 20px;
    height: 15px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 20px;
}